.mu-footer {
  display: none;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  color: $gray-700;
  background-color: $gray-900;

  a {
    color: $gray-700;
    transition: $transition;
    &:hover {
      color: $gray-500;
      text-decoration: none;
    }
  }

  .footer-title {
    color: $gray-500;
    font-size: 16px;
    padding: 0.75rem 0;
    margin-bottom: 0.75rem;
  }

  .friend-links {
    a {
      display: inline-block;
      margin-right: 1.25rem;
      margin-bottom: 0.75rem;
    }
  }

  .footer-divider {
    display: block;
    width: 100%;
    height: 1px;
    background-color: $gray-800;
  }

  // 992px以下不显示
  @media (min-width: 992px) {
    display: flex;
  }
}

.mu-footer-wap {
  padding: $spacer;
  font-size: $font-size-sm;
  text-align: center;
  color: $gray-600;
  margin-bottom: 3rem;

  p {
    margin-bottom: 0;
  }

  a {
    color: $gray-900;
    font-weight: bold;
  }

  // 992px以上不显示
  @media (min-width: 992px) {
    display: none;
  }
}
